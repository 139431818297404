import * as React from "react"

import {
  StyledNavigationItem,
  StyledNavigationLink,
  StyledNavigationExternalLink,
} from "./styles"

const Navigationitem = ({ name, to, cta, settings }) => {
  const ctaBackgroundColor =
    settings.button_cta_background_color.color || "transparent"

  const linkColor = cta
    ? settings.button_cta_color.color
    : settings.nav_link_color.color || "#fff"

  const renderLink = () => (
    <StyledNavigationLink
      to={to}
      prefetch="true"
      $cta={cta}
      $ctaBackgroundColor={ctaBackgroundColor}
      $linkColor={linkColor}
    >
      {name}
    </StyledNavigationLink>
  )

  const renderExternalLink = () => (
    <StyledNavigationExternalLink
      href={to}
      prefetch="true"
      $cta={cta}
      $ctaBackgroundColor={ctaBackgroundColor}
      $linkColor={linkColor}
    >
      {name}
    </StyledNavigationExternalLink>
  )

  const generateLink = () =>
    to.includes("http") ? renderExternalLink() : renderLink()

  return <StyledNavigationItem>{generateLink()}</StyledNavigationItem>
}

export default Navigationitem
