import React from "react"
import SbEditable from "storyblok-react"
import { useStaticQuery, graphql } from "gatsby"
import PostListCard from "../PostListCard"

import { StyledCta, StyledCtaWrapper, StyledPostsList } from "./styles"
// import rewriteSlug from "../utils/rewriteSlug"

const PostsListPreview = ({ blok }) => {
  let filteredPosts = []
  const isResolved = typeof blok.posts[0] !== "string"

  const data = useStaticQuery(graphql`
    {
      posts: allStoryblokEntry(
        limit: 6
        sort: { order: DESC, fields: first_published_at }
        filter: { field_component: { eq: "blogpost" } }
      ) {
        edges {
          node {
            id
            uuid
            name
            slug
            full_slug
            content
            created_at
            first_published_at
          }
        }
      }
    }
  `)

  if (!isResolved) {
    // filteredPosts = data.posts.edges.filter(
    //   p => blok.posts.indexOf(p.node.uuid) > -1
    // )

    filteredPosts = data.posts.edges.map((p, i) => {
      const content = p.node.content
      const newContent =
        typeof content === "string" ? JSON.parse(content) : content
      p.node.content = newContent
      return p.node
    })
  }

  const arrayOfPosts = isResolved ? blok.posts : filteredPosts

  const ctaButtonText = blok.cta_button_text || "View All News"
  const ctaButtonUrl = blok.cta_button_url || "/news"

  const renderCtaButton = () => {
    if (arrayOfPosts.length >= 6) {
      return (
        <StyledCtaWrapper>
          <StyledCta to={ctaButtonUrl}>{ctaButtonText}</StyledCta>
        </StyledCtaWrapper>
      )
    }
    return null
  }

  return (
    <SbEditable content={blok} key={blok._uid}>
      <StyledPostsList>
        {arrayOfPosts.map(post => {
          return <PostListCard post={post} key={post.id} />
        })}
      </StyledPostsList>
      {renderCtaButton()}
    </SbEditable>
  )
}

export default PostsListPreview
