import React from "react"
import DynamicComponent from "../DynamicComponent"
import SbEditable from "storyblok-react"

import { StyledIframe, StyledIframeItem } from "./styles"

const Iframe = ({ blok }) => (
  <SbEditable content={blok} key={blok._uid}>
    <StyledIframe
      src={blok.src}
      width={blok.width}
      height={blok.height}
      scrolling="no"
      frameborder="no"
      allow="autoplay"
    />
  </SbEditable>
)

export default Iframe
