import React from "react"
import SbEditable from "storyblok-react"
import { render } from "storyblok-rich-text-react-renderer"
import { useStaticQuery, graphql } from "gatsby"
const { DateTime } = require("luxon")
import {
  StyledDate,
  StyledDescription,
  StyledLocation,
  StyledTicketUrl,
  StyledVenue,
  StyledVenueLocation,
  StyledEventHeader,
} from "./styles"

import Audio from "../Audio"
import Video from "../Video"
import Image from "../Image"

import DynamicComponent from "../DynamicComponent"

const Event = ({ blok }) => {
  const related =
    blok.related && blok.related.length ? (
      <DynamicComponent blok={blok.related[0]} key={blok.related[0]._uid} />
    ) : null

  const renderImage = () => {
    if (blok.image && blok.image.filename) {
      return <Image src={blok.image.filename} alt={blok.title} />
    }
  }

  const renderTicketUrl = () => {
    if (blok.ticket_url) {
      return (
        <div>
          <StyledTicketUrl href={blok.ticket_url} target="_new">
            GET TICKETS
          </StyledTicketUrl>
        </div>
      )
    }
  }

  return (
    <SbEditable content={blok} key={blok._uid}>
      <div>
        <div>
          <h1>{blok.title}</h1>
          <StyledDate>
            {DateTime.fromSQL(blok.date).toLocaleString({
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </StyledDate>
          <StyledEventHeader>
            <StyledVenueLocation>
              <StyledVenue>{blok.venue}</StyledVenue>
              <StyledLocation>{blok.location}</StyledLocation>
            </StyledVenueLocation>

            {renderTicketUrl()}
          </StyledEventHeader>
          {renderImage()}
          <StyledDescription>{blok.intro}</StyledDescription>
        </div>
      </div>
      <div>
        <div>
          {render(blok.long_text, {
            blokResolvers: {
              ["video"]: props => <Video {...props} />,
              ["audio"]: props => <Audio {...props} />,
            },
          })}
        </div>

        {related}
      </div>
    </SbEditable>
  )
}

export default Event
