import * as React from "react"

import {
  StyledHeader,
  StyledHeaderInner,
  StyledHeading,
  StyledLink,
  StyledLogo,
  StyledLogoLink,
} from "./styles"
import Navigation from "../Navigation"

const Header = ({ settings, fullscreen }) => {
  const renderLogo = () => {
    if (settings.logo.filename) {
      return (
        <StyledLogoLink to="/" $fullscreen={fullscreen}>
          <StyledLogo src={settings.logo.filename} alt="logo" />
        </StyledLogoLink>
      )
    }
  }

  const backgroundColor = fullscreen
    ? "transparent"
    : settings.header_background_color.color || "#000"

  const height = settings.height || "10vh"

  return (
    <StyledHeader
      height={height}
      $backgroundColor={backgroundColor}
      $fullscreen={fullscreen}
    >
      <StyledHeaderInner>
        {renderLogo()}
        <Navigation settings={settings} />
      </StyledHeaderInner>
    </StyledHeader>
  )
}

export default Header
