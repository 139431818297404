import React from "react"
import SbEditable from "storyblok-react"
import {
  StyledFeature,
  StyledFeatureInfo,
  StyledFeatureTitle,
  StyledFeatureDescription,
} from "./styles"

const Feature = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <StyledFeature>
        <StyledFeatureInfo>
          <StyledFeatureTitle>{blok.name}</StyledFeatureTitle>
          <StyledFeatureDescription>
            {blok.description}
          </StyledFeatureDescription>
        </StyledFeatureInfo>
      </StyledFeature>
    </SbEditable>
  )
}

export default Feature
