import React from "react"
import Mail from "./Mail"
import CaretDown from "./CaretDown"
import Facebook from "./Facebook"
import Instagram from "./Instagram"
import Soundcloud from "./Soundcloud"
import Twitter from "./Twitter"
import Youtube from "./Youtube"

const Components = {
  instagram: Instagram,
  facebook: Facebook,
  soundcloud: Soundcloud,
  twitter: Twitter,
  youtube: Youtube,
  mail: Mail,
  caretdown: CaretDown,
}

const DynamicIcon = ({ type }) => {
  if (typeof Components[type] !== "undefined") {
    const Component = Components[type]
    return <Component />
  }
  return null
}

export default DynamicIcon
