import styled from "styled-components"
import { Link } from "gatsby"

export const StyledPostsList = styled.ul`
  display: block;
  list-style: none;
  margin: 0;

  @media only screen and (min-width: 600px) {
    display: flex;
    flex-wrap: wrap;
  }
`

export const StyledCta = styled(Link)`
  border: 0;
  padding: 10px;
  background-color: red;
  color: white;
  margin: 0 auto;
  border-radius: ${({
    theme: {
      shape: { borderRadius },
    },
  }) => `${borderRadius * 2}px`};
  text-decoration: none;
  font-weight: bold;
  display: inline-block;

  &:hover {
    opacity: 0.7;
  }
`

export const StyledCtaWrapper = styled.div`
  margin: 2em 0;
  display: flex;
  justify-content: center;
`
