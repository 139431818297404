import * as React from "react"
import { render } from "storyblok-rich-text-react-renderer"

import { StyledFooter, StyledFooterText } from "./styles"
import { StyledWrapper } from "../Layout/styles"

import SocialIcons from "../SocialIcons"
const Footer = ({ settings }) => {
  const footerText = settings.footer_text ? render(settings.footer_text) : ""
  return (
    <StyledWrapper>
      <StyledFooter>
        <StyledFooterText>{footerText}</StyledFooterText>
        <SocialIcons settings={settings} />
      </StyledFooter>
    </StyledWrapper>
  )
}

export default Footer
