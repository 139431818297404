import styled from "styled-components"

export const StyledSong = styled.div`
  font-size: 0.8em;
  display: flex;

  border-bottom: 1px solid #c0c0c0;
  margin: 0;
  padding: 0.5em;
`

export const StyledSongNumber = styled.div`
  width: 5%;
`

export const StyledSongArtist = styled.div`
  width: 45%;
`
export const StyledSongName = styled.div`
  width: 40%;
`
export const StyledSongTime = styled.div`
  width: 10%;
  text-align: right;
`
