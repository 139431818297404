import React, { useState } from "react"
const { DateTime } = require("luxon")
import { InView } from "react-intersection-observer"

import {
  StyledPostListCard,
  StyledPostListCardDescription,
  StyledPostListCardEventDate,
  StyledPostListCardLink,
  StyledPostListCardLinkTitle,
  StyledPostListCardImage,
  StyledPostListCardInfo,
  StyledPostListCardLocation,
  StyledPostListCardVenue,
} from "./styles"

const PostListCard = ({ post }) => {
  const [isLoaded, setIsLoaded] = useState(false)

  const cardImage =
    typeof post.content.image === "string"
      ? post.content.image
      : post.content.image.filename

  const previewImage = cardImage
    ? cardImage
        .replace("a.storyblok", "img2.storyblok")
        .replace(".com/", ".com/500x0/")
    : ""

  const loadImage = () => {
    const bgImg = new Image()
    bgImg.onload = () => {
      setIsLoaded(true)
    }
    bgImg.onerror = () => {
      console.error("Failed to load ", cardImage)
    }
    bgImg.src = cardImage
  }

  const renderDate = () => {
    if (!post.content.date) {
      return null
    }
    return (
      <StyledPostListCardEventDate>
        {DateTime.fromSQL(post.content.date).toLocaleString({
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </StyledPostListCardEventDate>
    )
  }

  const renderDescription = () => {
    if (post.content.component === "event") {
      return (
        <>
          <StyledPostListCardVenue>
            {post.content.venue}
          </StyledPostListCardVenue>
          <StyledPostListCardLocation>
            {post.content.location}
          </StyledPostListCardLocation>
        </>
      )
    }

    return (
      <StyledPostListCardDescription>
        {post.content.intro}
      </StyledPostListCardDescription>
    )
  }

  const renderCard = inView => {
    if (inView) {
      loadImage()
    }

    return (
      <>
        <StyledPostListCardLink to={`/${post.full_slug}`}>
          <StyledPostListCardImage
            image={previewImage}
            preview={previewImage}
            isLoaded={isLoaded}
          />
        </StyledPostListCardLink>
        <StyledPostListCardInfo>
          {renderDate()}
          <StyledPostListCardLinkTitle to={`/${post.full_slug}`}>
            {post.content.title}
          </StyledPostListCardLinkTitle>
          {renderDescription()}
        </StyledPostListCardInfo>
      </>
    )
  }

  return (
    <InView triggerOnce threshold={0.5}>
      {({ inView, ref }) => {
        return (
          <StyledPostListCard key={post.id} ref={ref} $show={inView}>
            {renderCard(inView)}
          </StyledPostListCard>
        )
      }}
    </InView>
  )
}

export default PostListCard
