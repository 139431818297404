import styled from "styled-components"
import ReactPlayer from "react-player/lazy"

export const StyledVideoWrapper = styled.div`
  width: auto;
  height: auto;
`

export const StyledVideo = styled(ReactPlayer)`
  padding-top: 56.25%; // Percentage ratio for 16:9
  position: relative; // Set to relative

  > div {
    top: 0;
    position: absolute; // Scaling will occur since parent is relative now
  }
`
