import React from "react"
import { StyledCta, StyledCtaLink } from "./styles"

const Cta = ({ url, text, backgroundColor, linkColor }) => {
  return (
    <StyledCta>
      <StyledCtaLink
        href={url}
        $backgroundColor={backgroundColor}
        $linkColor={linkColor}
        target="_new"
      >
        {text}
      </StyledCtaLink>
    </StyledCta>
  )
}

export default Cta
