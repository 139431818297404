import styled from "styled-components"

export const StyledFeature = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`
export const StyledFeatureInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`

export const StyledFeatureTitle = styled.div`
  font-weight: bold;
`

export const StyledFeatureDescription = styled.p`
  text-align: center;
`
