import styled from "styled-components"

export const StyledGrid = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 20px 0;
  flex-direction: column;

  @media only screen and (min-width: 960px) {
    flex-direction: row;
  }
`

export const StyledGridItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: center;
  width: 100%;

  @media only screen and (min-width: 960px) {
    width: 33%;
  }
`
