import styled from "styled-components"

export const StyledNavigation = styled.nav`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  color: ${({ settings }) => settings.nav_link_color.color || "#fff"};

  @media only screen and (min-width: 960px) {
    flex-direction: row;
  }
`

export const StyledNavigationMenu = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: ${props => (props.show ? 0 : "-100vh")};
  left: 0;
  z-index: 500;
  background-color: #000;
  background-opacity: 0.9;
  transition: all 0.5s 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);

  @media only screen and (min-width: 960px) {
    position: relative;
    top: initial;
    width: auto;
    height: auto;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    transition: none;
  }
`

export const StyledHamburgerMenu = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  background: transparent;
  z-index: 900000;
  border: 0;
  margin: auto;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: all 0.5s 1s cubic-bezier(0.215, 0.61, 0.355, 1);

  @media only screen and (min-width: 960px) {
    display: none;
  }
`

export const StyledHamburgerMenuPatty = styled.div`
  width: ${props => props.barWidth};
  height: ${props => props.barHeight};
  position: relative;
  background: ${({ color, active }) => (active ? "transparent" : color)};
  transition: all 0ms cubic-bezier(0.215, 0.61, 0.355, 1);

  &:after,
  &:before {
    content: "";
    position: absolute;
    left: 0;
    width: ${props => props.barWidth};
    height: ${props => props.barHeight};
  }

  &:before {
    bottom: ${({ active, barSpacing }) => (active ? "0" : barSpacing)};
    background: ${({ color }) => color};
    transition: bottom 0.15s 0.15s cubic-bezier(0, 0, 0.58, 1),
      background 0.15s 0.15s cubic-bezier(0, 0, 0.58, 1),
      transform 0.15s cubic-bezier(0, 0, 0.58, 1);
    transform: ${({ active }) => (active ? "rotate(45deg)" : "none")};
  }

  &:after {
    top: ${({ active, barSpacing }) => (active ? "0" : barSpacing)};
    background: ${props => props.color};
    transition: top 0.15s 0.15s cubic-bezier(0, 0, 0.58, 1),
      background 0.15s 0.15s cubic-bezier(0, 0, 0.58, 1),
      transform 0.15s cubic-bezier(0, 0, 0.58, 1);
    transform: ${({ active }) => (active ? "rotate(-45deg)" : "none")};
  }

  @media only screen and (min-width: 960px) {
    display: none;
  }
`
