import styled from "styled-components"

export const StyledDate = styled.div`
  font-size: 1.2em;
  margin-bottom: 1em;
  /* opacity: 0.5; */
`

export const StyledDescription = styled.div`
  margin-bottom: 1em;
`

export const StyledLocation = styled.div`
  margin-bottom: 1em;
`

export const StyledEventHeader = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledVenueLocation = styled.div``

export const StyledVenue = styled.div`
  font-weight: bold;
  margin-bottom: 0;
  line-height: 1;
  font-size: 1.2em;
`

export const StyledTicketUrl = styled.a`
  border: 0;
  padding: 10px;
  background-color: red;
  color: white;
  border-radius: ${({
    theme: {
      shape: { borderRadius },
    },
  }) => `${borderRadius * 2}px`};
  text-decoration: none;
  font-weight: bold;
  display: inline-block;

  &:hover {
    opacity: 0.7;
  }
`
