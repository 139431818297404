import styled from "styled-components"

export const StyledEventsList = styled.ul`
  display: block;
  list-style: none;
  margin: 0;

  @media only screen and (min-width: 600px) {
    display: flex;
    flex-wrap: wrap;
  }
`

export const StyledEventsHeading = styled.h2`
  text-align: center;
  margin: 50px 0 50px 0;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    border-bottom: 6px solid #000;
  }
`
