import React from "react"

import DynamicIcon from "../Icons/DynamicIcon"
import { StyledSocialIcon, StyledSocialIcons } from "./styles"
const SocialIcons = ({ settings }) => {
  const renderIcon = ({ icon, url }) => (
    <StyledSocialIcon href={url} target="new" key={url} settings={settings}>
      <DynamicIcon type={icon} />
    </StyledSocialIcon>
  )

  const icons = ["instagram", "facebook", "twitter", "soundcloud", "youtube"]

  return (
    <StyledSocialIcons>
      {icons.map(icon => {
        if (settings[icon]) {
          return renderIcon({ icon, url: settings[icon] })
        }
      })}
    </StyledSocialIcons>
  )
}

export default SocialIcons
