import React from "react"

import DynamicComponent from "../DynamicComponent"
import SbEditable from "storyblok-react"

import { StyledAudio, StyledAudioWrapper } from "./styles"

const Audio = ({ url }) => {
  // todo parse supported services by url
  // YouTube, Facebook, Twitch, SoundCloud, Streamable, Vimeo, Wistia and DailyMotion

  const audioUrl = url

  if (audioUrl) {
    return (
      <StyledAudioWrapper>
        <StyledAudio width="100%" height="100%" url={url} />
      </StyledAudioWrapper>
    )
  }

  return <div>Audio format not supported</div>
}

export default Audio
