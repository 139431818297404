import * as React from "react"
import {
  StyledTeaser,
  StyledTeaserHeading,
  StyledTeaserDescription,
} from "./styles"

const Teaser = ({ blok }) => (
  <StyledTeaser>
    <StyledTeaserHeading>{blok.headline}</StyledTeaserHeading>
    <StyledTeaserDescription>{blok.subheading}</StyledTeaserDescription>
  </StyledTeaser>
)

export default Teaser
