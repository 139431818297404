import styled from "styled-components"

export const StyledFooter = styled.div`
  margin: 50px 0 50px 0;
  border-top: 1px solid #000;
  padding-top: 20px;

  @media only screen and (min-width: 960px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const StyledFooterText = styled.div`
  font-size: 12px;
  text-align: center;
  margin-bottom: 20px;

  p {
    margin-bottom: 0.2em;
  }

  @media only screen and (min-width: 960px) {
    text-align: left;
    margin-bottom: 0;
  }
`
