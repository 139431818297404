/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"

import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ settings, pageDescription, pageTitle, pageImage }) {
  const siteTitle = settings.title
  const metaDescription = pageDescription || settings.site_description
  const faviconImage =
    (settings && settings.favicon && settings.favicon.filename) || "favicon.ico"

  const settingsOgImage =
    (settings && settings.og_image && settings.og_image.filename) ||
    faviconImage

  const ogImage = pageImage || settingsOgImage || faviconImage

  return (
    <Helmet
      title={pageTitle ? `${pageTitle} | ${siteTitle}` : `${siteTitle}`}
      link={[{ rel: "icon", type: "image/png", href: faviconImage }]}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          property: `og:title`,
          content: siteTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteTitle,
        },
        {
          name: `twitter:title`,
          content: siteTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ]}
    />
  )
}

export default Seo
