import { graphql } from "gatsby"
import React, { useState } from "react"
import SbEditable from "storyblok-react"

import NavigationItem from "../NavigationItem"
import {
  StyledHamburgerMenu,
  StyledNavigation,
  StyledNavigationMenu,
  StyledHamburgerMenuPatty,
} from "./styles"

const Navigation = ({ settings }) => {
  let [isMenuOpen, setIsMenuOpen] = useState(false)

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  const barWidth = "24px"
  const barHeight = "2px"
  const barSpacing = "7px"

  return (
    <StyledNavigation role="navigation" settings={settings}>
      <StyledHamburgerMenu
        barWidth={barWidth}
        barHeight={barHeight}
        barSpacing={barSpacing}
        type="button"
        onClick={handleToggleMenu}
      >
        <StyledHamburgerMenuPatty
          barWidth={barWidth}
          barHeight={barHeight}
          barSpacing={barSpacing}
          color="#ffffff"
          active={isMenuOpen}
        />
      </StyledHamburgerMenu>
      <StyledNavigationMenu show={isMenuOpen}>
        {settings &&
          settings.nav_items.map((navitem, index) => {
            return (
              <NavigationItem
                key={index}
                to={navitem.link.url}
                name={navitem.name}
                cta={navitem.cta_button}
                settings={settings}
              />
            )
          })}
      </StyledNavigationMenu>
    </StyledNavigation>
  )
}

export default Navigation
