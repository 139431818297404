import React, { useState } from "react"
import { InView } from "react-intersection-observer"
import SbEditable from "storyblok-react"
import easyScroll from "easy-scroll"

import DynamicComponent from "../DynamicComponent"
import DynamicIcon from "../Icons/DynamicIcon"

import {
  StyledCaretButton,
  StyledHero,
  StyledHeroImage,
  StyledFeaturedImage,
} from "./styles"

const Hero = ({ blok }) => {
  const [isLoaded, setIsLoaded] = useState(false)

  const postImage =
    typeof blok.hero_image === "string"
      ? blok.hero_image
      : blok.hero_image.filename

  const previewImage = postImage
    ? postImage
        .replace("a.storyblok", "img2.storyblok")
        .replace(".com/", ".com/50x0/")
    : ""

  const scrollPage = () => {
    easyScroll({
      scrollableDomEle: window,
      direction: "bottom",
      duration: 500,
      easingPreset: "easeOutCubic",
      scrollAmount: window ? window.innerHeight - window.scrollY : 1000,
    })
  }

  const renderFeaturedImage = () => {
    if (blok.featured_image && blok.featured_image.filename) {
      return (
        <StyledFeaturedImage
          src={blok.featured_image.filename}
          alt="featured"
        />
      )
    }
  }

  const renderCaret = () => {
    if (blok.fullscreen) {
      return (
        <StyledCaretButton onClick={scrollPage}>
          <DynamicIcon type="caretdown" />
        </StyledCaretButton>
      )
    }
  }

  const loadImage = () => {
    const bgImg = new Image()
    bgImg.onload = () => {
      setIsLoaded(true)
    }
    bgImg.onerror = () => {
      console.error("Failed to load ", postImage)
    }
    bgImg.src = postImage
  }

  const renderHero = inView => {
    if (inView) {
      loadImage()
    }

    return (
      <>
        {renderFeaturedImage()}
        {renderCaret()}
      </>
    )
  }

  return (
    <InView triggerOnce threshold={0.5}>
      {({ inView, ref }) => {
        return (
          <SbEditable content={blok} key={blok._uid}>
            <StyledHero
              fullscreen={blok.fullscreen}
              image={blok.hero_image.filename}
              preview={previewImage}
              ref={ref}
              isLoaded={isLoaded}
            >
              {renderHero(inView)}
            </StyledHero>
          </SbEditable>
        )
      }}
    </InView>
  )
}

export default Hero
