import styled from "styled-components"

export const StyledCta = styled.div`
  text-align: center;
  padding: 20px;
`

export const StyledCtaLink = styled.a`
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  border-radius: ${({
    theme: {
      shape: { borderRadius },
    },
  }) => `${borderRadius * 2}px`};
  padding: 10px;
  color: ${({ $linkColor }) => $linkColor};
  text-decoration: none;
  text-transform: uppercase;
  font-size: 20px;

  &:hover {
    opacity: 0.9;
  }

  @media only screen and (min-width: 960px) {
    font-size: 16px;
  }
`
