import styled from "styled-components"
import ReactPlayer from "react-player/lazy"

export const StyledAudioWrapper = styled.div`
  width: auto;
  height: auto;
`

export const StyledAudio = styled(ReactPlayer)`
  position: relative; // Set to relative

  > div {
    top: 0;
    position: absolute; // Scaling will occur since parent is relative now
  }
`
