import React from "react"
import SbEditable from "storyblok-react"
import { useStaticQuery, graphql } from "gatsby"
import PostListCard from "../PostListCard"
const { DateTime } = require("luxon")

import { StyledEventsHeading, StyledEventsList } from "./styles"
// import rewriteSlug from "../utils/rewriteSlug"

const EventsList = ({ blok }) => {
  let filteredPosts = []
  const isResolved = false

  const data = useStaticQuery(graphql`
    {
      posts: allStoryblokEntry(
        sort: { order: DESC, fields: field_date_string }
        filter: { field_component: { eq: "event" } }
      ) {
        edges {
          node {
            id
            name
            slug
            full_slug
            content
          }
        }
      }
    }
  `)
  if (!isResolved) {
    // filteredPosts = data.posts.edges.filter(
    //   p => blok.posts.indexOf(p.node.uuid) > -1
    // )

    filteredPosts = data.posts.edges.map((p, i) => {
      const content = p.node.content
      const newContent =
        typeof content === "string" ? JSON.parse(content) : content
      p.node.content = newContent
      return p.node
    })
  }

  const upcomingEvents = filteredPosts.filter(event => {
    const eventDate = DateTime.fromSQL(event.content.date)
    const today = DateTime.local().startOf("day")
    return eventDate >= today
  })

  const pastEvents = filteredPosts.filter(event => {
    const eventDate = DateTime.fromSQL(event.content.date)
    const today = DateTime.local().startOf("day")
    return eventDate < today
  })

  // const arrayOfEvents = isResolved ? blok.posts : filteredPosts

  const upcomingHeadingText = blok.upcoming_heading_text || "Upcoming Events"
  const pastHeadingText = blok.past_heading_text || "Past Events"

  const renderUpcomingEvents = () => {
    if (upcomingEvents.length) {
      return (
        <>
          <StyledEventsHeading>{upcomingHeadingText}</StyledEventsHeading>
          <StyledEventsList>
            {upcomingEvents.map(post => {
              return <PostListCard post={post} key={post.id} />
            })}
          </StyledEventsList>
        </>
      )
    }
    return null
  }

  const renderPastEvents = () => {
    if (pastEvents.length) {
      const renderHeading = () => {
        if (upcomingEvents.length) {
          return <StyledEventsHeading>{pastHeadingText}</StyledEventsHeading>
        }
        return <StyledEventsHeading>Events</StyledEventsHeading>
      }
      return (
        <>
          {renderHeading()}
          <StyledEventsList>
            {pastEvents.map(post => {
              return <PostListCard post={post} key={post.id} />
            })}
          </StyledEventsList>
        </>
      )
    }
    return null
  }

  return (
    <SbEditable content={blok} key={blok._uid}>
      {renderUpcomingEvents()}
      {renderPastEvents()}
    </SbEditable>
  )
}

export default EventsList
