require("dotenv").config({
  path: `.env.${process.env.NODE_ENV}`,
})

module.exports = {
  siteMetadata: {
    title: process.env.SITE_TITLE,
    siteUrl: process.env.SITE_URL,
    description: process.env.SITE_DESCRIPTION,
  },
  plugins: [
    {
      resolve: `gatsby-plugin-feed`,
      options: {
        query: `
          {
            site {
              siteMetadata {
                title
                description
                siteUrl
                site_url: siteUrl
              }
            }
          }
        `,
        feeds: [
          {
            serialize: ({ query: { site, allStoryblokEntry } }) => {
              return allStoryblokEntry.edges.map(edge => {
                const contentParsed = JSON.parse(edge.node.content)
                const hashTagsArray = edge.node.tag_list
                const hashTags =
                  hashTagsArray && hashTagsArray.length
                    ? hashTagsArray.map(tag => `#${tag}`).join(" ")
                    : " "
                const postContent = `${edge.node.field_intro_string}<br><img src="${contentParsed.image.filename}"><br>${hashTags}`

                return Object.assign({}, edge.node.id, {
                  title: edge.node.field_title_string,
                  description: edge.node.field_intro_string,
                  date: edge.node.first_published_at,
                  url: `${site.siteMetadata.siteUrl}/${edge.node.full_slug}`,
                  guid: `${site.siteMetadata.siteUrl}/${edge.node.full_slug}`,
                  custom_elements: [
                    {
                      "content:encoded": {
                        _cdata: postContent,
                      },
                    },
                  ],
                })
              })
            },
            query: `
              {
                allStoryblokEntry(
                  sort: { order: DESC, fields: first_published_at }
                  filter: { field_component: {in: ["blogpost", "event", "album"]} }
                ) {
                  edges {
                    node {
                      id
                      name
                      field_component
                      field_title_string
                      field_intro_string
                      full_slug
                      created_at
                      first_published_at
                      content
                      tag_list
                    }
                  }
                }
              }
            `,
            output: "/rss.xml",
            title: `${process.env.SITE_TITLE} RSS Feed`,
          },
        ],
      },
    },
    `gatsby-plugin-image`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-plugin-react-helmet`,
    {
      resolve: "gatsby-source-storyblok",
      options: {
        accessToken: process.env.STORYBLOK_TOKEN,
        version: "draft",
      },
    },
    `gatsby-plugin-styled-components`,
    {
      resolve: `gatsby-plugin-google-fonts`,
      options: {
        fonts: [process.env.GOOGLE_FONTS],
        display: "swap",
      },
    },
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ],
}
