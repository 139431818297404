import React from "react"

function Icon() {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="m23.953 4.57a10 10 0 0 1 -2.825.775 4.958 4.958 0 0 0 2.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 0 0 -8.384 4.482c-4.09-.193-7.713-2.158-10.14-5.126a4.822 4.822 0 0 0 -.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 0 1 -2.228-.616v.06a4.923 4.923 0 0 0 3.946 4.827 4.996 4.996 0 0 1 -2.212.085 4.936 4.936 0 0 0 4.604 3.417 9.867 9.867 0 0 1 -6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 0 0 7.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63a9.935 9.935 0 0 0 2.46-2.548z"
      />
    </svg>
  )
}

export default Icon
