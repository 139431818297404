import React from "react"

import DynamicComponent from "../DynamicComponent"
import SbEditable from "storyblok-react"

import { StyledVideo, StyledVideoWrapper } from "./styles"

const Video = ({ url }) => {
  // todo parse supported services by url
  // YouTube, Facebook, Twitch, SoundCloud, Streamable, Vimeo, Wistia and DailyMotion
  const videoUrl = url

  if (videoUrl) {
    return (
      <StyledVideoWrapper>
        <StyledVideo width="100%" height="100%" url={url} />
      </StyledVideoWrapper>
    )
  }

  return <div>video format not supported</div>
}

export default Video
