import React from "react"
import SbEditable from "storyblok-react"
import { render } from "storyblok-rich-text-react-renderer"
import { useStaticQuery, graphql } from "gatsby"
const { DateTime } = require("luxon")
import { StyledDate, StyledDescription } from "./styles"

import Image from "../Image"

import DynamicComponent from "../DynamicComponent"

const BlogPost = ({ blok, createdAt, firstPublishedAt }) => {
  const postDate = firstPublishedAt ? firstPublishedAt : createdAt
  const related =
    blok.related && blok.related.length ? (
      <DynamicComponent blok={blok.related[0]} key={blok.related[0]._uid} />
    ) : null
  // const { authors } = useStaticQuery(graphql`
  //   {
  //     authors: allStoryblokEntry(
  //       filter: { field_component: { eq: "author" } }
  //     ) {
  //       edges {
  //         node {
  //           name
  //           uuid
  //           content
  //         }
  //       }
  //     }
  //   }
  // `)

  // let thisAuthor = authors.edges.filter(({ node }) => node.uuid === blok.author)
  // let authorContent = thisAuthor.length
  //   ? JSON.parse(thisAuthor[0].node.content)
  //   : {}

  const renderImage = () => {
    if (blok.image && blok.image.filename) {
      return <Image src={blok.image.filename} alt={blok.title} />
    }
  }

  return (
    <SbEditable content={blok} key={blok._uid}>
      <div>
        <div>
          <h1>{blok.title}</h1>

          <StyledDate>
            {DateTime.fromISO(postDate).toLocaleString({
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </StyledDate>
          <StyledDescription>{blok.intro}</StyledDescription>

          {renderImage()}
        </div>
      </div>
      <div>
        <div>{render(blok.long_text)}</div>

        {related}
      </div>
    </SbEditable>
  )
}

export default BlogPost
