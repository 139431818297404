import React from "react"
import DynamicComponent from "../DynamicComponent"
import SbEditable from "storyblok-react"
import { Widget } from "@typeform/embed-react"

const Typeform = ({ blok }) => (
  <SbEditable content={blok} key={blok._uid}>
    <Widget
      id={blok.id}
      data-tf-hide-headers
      data-tf-opacity={blok.opacity}
      style={{ width: blok.width, height: blok.height }}
    />
  </SbEditable>
)

export default Typeform
