import React from "react"
import SbEditable from "storyblok-react"
import { render } from "storyblok-rich-text-react-renderer"
import { useStaticQuery, graphql } from "gatsby"
const { DateTime } = require("luxon")
import {
  StyledSong,
  StyledSongArtist,
  StyledSongName,
  StyledSongNumber,
  StyledSongTime,
} from "./styles"

import Image from "../Image"
import Audio from "../Audio"
import Video from "../Video"
import Cta from "../Cta"

import DynamicComponent from "../DynamicComponent"

const Album = ({ blok, createdAt }) => {
  // blok.file_url
  const related =
    blok.related && blok.related.length ? (
      <DynamicComponent blok={blok.related[0]} key={blok.related[0]._uid} />
    ) : null

  const renderImage = () => {
    if (blok.image && blok.image.filename) {
      return <Image src={blok.image.filename} alt={blok.title} />
    }
  }

  const renderPurchase = () => {
    // TODO: VALIDATE URL FORMAT
    const isValidUrl = true
    if (blok.purchase_url && isValidUrl) {
      return (
        <Cta
          url={blok.purchase_url}
          text="BUY"
          backgroundColor="#000"
          linkColor="#fff"
        />
      )
    }
  }

  const renderSong = (song, index) => {
    return (
      <StyledSong key={index}>
        <StyledSongNumber>{index + 1}</StyledSongNumber>
        <StyledSongArtist>{song.title}</StyledSongArtist>
        <StyledSongArtist>{song.artist}</StyledSongArtist>
        <StyledSongTime>{song.time}</StyledSongTime>
      </StyledSong>
    )
  }

  const renderSongs = () => {
    if (blok.songs && blok.songs.length) {
      return (
        <div>{blok.songs.map((song, index) => renderSong(song, index))}</div>
      )
    }
  }

  return (
    <SbEditable content={blok} key={blok._uid}>
      <div>
        <div>
          <h1>{blok.title}</h1>
          <p>{blok.intro}</p>

          {renderImage()}
        </div>
      </div>
      <div>
        <div>
          {render(blok.description, {
            blokResolvers: {
              ["video"]: props => <Video {...props} />,
              ["audio"]: props => <Audio {...props} />,
            },
          })}
        </div>
        {renderSongs()}
        {renderPurchase()}
        {related}
      </div>
    </SbEditable>
  )
}

export default Album
