import styled from "styled-components"

export const StyledTeaser = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledTeaserHeading = styled.h2`
  text-align: center;
  margin: 50px 0 50px 0;
  position: relative;
  line-height: 1;

  &:after {
    content: "";
    position: absolute;
    bottom: -1em;
    left: 50%;
    transform: translateX(-50%);
    width: 40px;
    border-bottom: 6px solid #000;
  }
`

export const StyledTeaserDescription = styled.p`
  display: none;
  text-align: center;
  margin: 0 auto 50px auto;
`
