import React from "react"
import DynamicComponent from "../DynamicComponent"
import SbEditable from "storyblok-react"

import { StyledGrid, StyledGridItem } from "./styles"

const Grid = ({ blok }) => (
  <SbEditable content={blok} key={blok._uid}>
    <StyledGrid>
      {blok.columns.map(blok => (
        <StyledGridItem key={blok._uid}>
          <DynamicComponent blok={blok} />
        </StyledGridItem>
      ))}
    </StyledGrid>
  </SbEditable>
)

export default Grid
