import styled from "styled-components"

export const StyledDate = styled.div`
  font-size: 0.8em;
  opacity: 0.5;
`

export const StyledDescription = styled.div`
  margin-bottom: 1em;
`
