import React, { useState, useEffect } from "react"
import DynamicComponent from "../DynamicComponent"
import SbEditable from "storyblok-react"
import { InView } from "react-intersection-observer"

import { StyledImage, StyledImageWrapper } from "./styles"

const Image = ({ src, alt }) => {
  const [isLoaded, setIsLoaded] = useState(false)

  const previewImage = src
    ? src
        .replace("a.storyblok", "img2.storyblok")
        .replace(".com/", ".com/10x0/")
    : ""

  const handleError = () => {
    console.error("Failed to load ", src)
  }

  const handleImageLoaded = () => {
    setIsLoaded(true)
  }

  const renderImage = inView => {
    if (!inView) {
      return null
    }

    return (
      <StyledImageWrapper preview={previewImage} isLoaded={isLoaded}>
        <StyledImage
          src={src}
          alt={alt}
          onLoad={handleImageLoaded}
          onError={handleError}
        />
      </StyledImageWrapper>
    )
  }

  return (
    <InView triggerOnce>
      {({ inView, ref }) => {
        return <div ref={ref}>{renderImage(inView)}</div>
      }}
    </InView>
  )
}

export default Image
