import React from "react"
import SbEditable from "storyblok-react"
import Album from "../Album"
import AlbumsList from "../AlbumsList"
import AlbumsListPreview from "../AlbumsListPreview"
import Body from "../Body"
import Feature from "../Feature"
import Event from "../Event"
import EventsList from "../EventsList"
import EventsListPreview from "../EventsListPreview"
import Grid from "../Grid"
import Hero from "../Hero"
import Iframe from "../Iframe"
import Teaser from "../Teaser"
import Typeform from "../Typeform"
import BlogPost from "../BlogPost"
import PostsList from "../PostsList"
import PostsListPreview from "../PostsListPreview"
import Video from "../Video"

import { StyledWrapper } from "../Layout/styles"

const Components = {
  album: Album,
  "albums-list": AlbumsList,
  "albums-list-preview": AlbumsListPreview,
  body: Body,
  event: Event,
  "events-list": EventsList,
  "events-list-preview": EventsListPreview,
  feature: Feature,
  grid: Grid,
  hero: Hero,
  iframe: Iframe,
  typeform: Typeform,
  teaser: Teaser,
  blogpost: BlogPost,
  "posts-list": PostsList,
  "posts-list-preview": PostsListPreview,
  video: Video,
}

const DynamicComponent = ({ blok, createdAt, firstPublishedAt }) => {
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component]

    if (blok.component === "hero") {
      return (
        <SbEditable content={blok}>
          <Component blok={blok} createdAt={createdAt} />
        </SbEditable>
      )
    }

    return (
      <SbEditable content={blok}>
        <StyledWrapper>
          <Component
            blok={blok}
            createdAt={createdAt}
            firstPublishedAt={firstPublishedAt}
          />
        </StyledWrapper>
      </SbEditable>
    )
  }
  return (
    <p>
      The component <strong>{blok.component}</strong> has not been created yet!
    </p>
  )
}

export default DynamicComponent
