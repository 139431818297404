import React from "react"
import SbEditable from "storyblok-react"
import { render } from "storyblok-rich-text-react-renderer"

import DynamicIcon from "../Icons/DynamicIcon"
import { StyledBody } from "./styles"

const Body = ({ blok }) => (
  <SbEditable content={blok} key={blok._uid}>
    <StyledBody>{render(blok.body)}</StyledBody>
  </SbEditable>
)

export default Body
