import styled from "styled-components"

export const StyledPostsList = styled.ul`
  display: block;
  list-style: none;
  margin: 0;

  @media only screen and (min-width: 600px) {
    display: flex;
    flex-wrap: wrap;
  }
`
